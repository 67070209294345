import { useUserStore } from "~/store/auth";

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();

  if (process.server) return;

  userStore.setLoading(true);
  const rol = userStore.getLogin?.Modulos?.find(
    (module) => module.CodigoModulo === MSCmodule
  );

  if (rol && rol?.CodigoRol === roles["technician"]) {
    userStore.setLoading(false);
    return abortNavigation({
      statusCode: 401,
    });
  }
});
